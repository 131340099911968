import logoLink from '../resources/rhe.svg';

export const cssVariables = {
    primary_light: '#CDECF9',
    primary: '#e30613',
    primary_dark: '#b6050f',
    text_on_primary: 'white',
    primary_transparent: '#FFF5F5',

    secondary: '#383838',
    secondary_light: '#EBEBEB',
    text_on_secondary: 'white',
    secondary_transparent: '#F7F7F7',

    font: '"SuisseIntl-Medium", Helvetica, Arial, sans-serif',
    link_color: 'rgb(0, 131, 177)',
    font_color: '#383838',

    border_radius: '0px',
    border_width: '1px',
    border_style: 'solid',

    active: '#CCFFCC',
    inactive: '#ffcccc',

    edit_color: '#e30613',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#383838',    

    dropdown_background: '#CDECF9',
    toggle_switch: 'rgb(120, 120, 120)',    
};

export const logo = logoLink;
